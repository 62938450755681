import React, { createContext, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { t } from 'i18n'
import { each } from 'lodash-es'
import { map } from 'lodash-es'
import { omit } from 'lodash-es'
import { size } from 'lodash-es'
import { uniqueId } from 'lodash-es'
import Toast from 'r/components/common/toasts/toast'
import styled from 'styled-components'

export const ToastDisplayContext = createContext({
  messages: {},
  addMessage: () => '',
  dismissMessage: () => {},
  handleModelError: () => {}
})

const ToastsContainer = styled.div`
  position: fixed;
  bottom: calc(50px + 1rem);
  right: 1rem;
  z-index: 2000;
`

const MESSAGE_TTL = 5000

const Message = ({ message: { id, message, kind, messageTTL }, dismiss }) => {
  const handleDismiss = useCallback(() => {
    dismiss(id)
  }, [id, dismiss])

  const title = kind === 'error' ? t('error') : ''

  return (
    <Toast onClose={handleDismiss} title={title} kind={kind} messageTTL={messageTTL}>
      {message}
    </Toast>
  )
}

const ToastDisplay = ({ children }) => {
  const [messages, setMessages] = useState({})

  const dismissMessage = useCallback(
    (id) => {
      setMessages((messages) => omit(messages, id))
    },
    [setMessages]
  )

  const addMessage = useCallback(
    (message, kind = null, messageTTL = MESSAGE_TTL) => {
      const id = uniqueId('toast-display')
      setMessages((messages) => ({ ...messages, [id]: { message, id, kind, messageTTL } }))
      setTimeout(() => dismissMessage(id), messageTTL)
      return id
    },
    [setMessages, dismissMessage]
  )
  // used to display toast after a redirect
  const addPendingMessage = (message, kind, messageTTL) => {
    const pendingToasts = JSON.parse(window.sessionStorage.getItem('pendingToasts')) || []
    pendingToasts.push({ kind, message, messageTTL })
    window.sessionStorage.setItem('pendingToasts', JSON.stringify(pendingToasts))
  }

  const handleModelError = useCallback(
    (errors, kind = 'error', messageTTL) => {
      each(errors, (attributeErrors) => {
        each(attributeErrors, (error) => addMessage(error, kind, messageTTL))
      })
    },
    [addMessage]
  )

  useEffect(() => {
    const pendingToasts = JSON.parse(window.sessionStorage.getItem('pendingToasts')) || []

    if (pendingToasts.length) {
      pendingToasts.forEach(({ message, kind, messageTTL }) => {
        addMessage(message, kind, messageTTL)
      })
      window.sessionStorage.removeItem('pendingToasts')
    }
  }, [addMessage])

  const hasMessages = size(messages) > 0

  return (
    <ToastDisplayContext.Provider value={{ messages, addMessage, addPendingMessage, dismissMessage, handleModelError }}>
      {children}
      {hasMessages && <MessagesBlock messages={messages} dismissMessage={dismissMessage} />}
    </ToastDisplayContext.Provider>
  )
}

const MessagesBlock = ({ messages, dismissMessage }) => {
  return ReactDOM.createPortal(
    <ToastsContainer>
      {map(messages, (message) => (
        <Message message={message} dismiss={dismissMessage} key={message.id} />
      ))}
    </ToastsContainer>,
    document.getElementById('toast-container')
  )
}

export default ToastDisplay
